<template>
  <v-row>
    <template>
      <v-col cols="12" class="pa-0">
        <v-breadcrumbs
          class="pl-0 primary--text"
          :items="navigator"
          divider="/"
        ></v-breadcrumbs>
      </v-col>
      <v-col class="pb-1" cols="12" style="border-bottom: 1px solid #ff6700">
        <strong class="primary--text">Builder Tools</strong>
      </v-col>

      <v-col cols="6" md="4" lg="2" class="d-flex justify-center">
        <router-link to="resume-builder">
          <v-card class="rscBox">
            <v-img :src="resume" contain class="ma-3 mb-0 rscCardImg"> </v-img>
            <v-card-subtitle
              class="primary--text justify-center text-center pt-0"
              >Resume Builder</v-card-subtitle
            >
          </v-card>
        </router-link>
      </v-col>
      <v-col cols="6" md="4" lg="2" class="d-flex justify-center">
        <router-link to="bio-data-builder">
          <v-card class="rscBox">
            <v-img :src="bioData" contain class="ma-3 mb-0 rscCardImg"> </v-img>
            <v-card-subtitle
              class="primary--text justify-center text-center pt-0"
              >BioData Builder</v-card-subtitle
            >
          </v-card>
        </router-link>
      </v-col>
      <v-col cols="6" md="4" lg="2" class="d-flex justify-center">
        <router-link to="">
          <v-card class="rscBox">
            <v-img :src="certificate" contain class="ma-3 mb-0 rscCardImg">
            </v-img>
            <v-card-subtitle
              class="primary--text justify-center text-center pt-0"
              >Certificate Builder</v-card-subtitle
            >
          </v-card>
        </router-link>
      </v-col>
      <v-col cols="6" md="4" lg="2" class="d-flex justify-center">
        <v-card class="rscBox justify-center align-center d-flex mt-3">
          <span class="primary--text">View All >> </span>
        </v-card>
      </v-col>
    </template>
    <template>
      <v-col
        class="mt-8 pb-1"
        cols="12"
        style="border-bottom: 1px solid #ff6700"
      >
        <strong class="primary--text">Developer Tools</strong>
      </v-col>

      <v-col cols="6" md="4" lg="2" class="d-flex justify-center">
        <router-link to="json-formatter">
          <v-card class="rscBox">
            <v-img :src="jsonFormat" contain class="ma-3 mb-0 rscCardImg">
            </v-img>
            <v-card-subtitle
              class="primary--text justify-center text-center pt-0"
              >JSON Formatter</v-card-subtitle
            >
          </v-card>
        </router-link>
      </v-col>
      <v-col cols="6" md="4" lg="2" class="d-flex justify-center">
        <router-link to="escape-unescape">
          <v-card class="rscBox">
            <v-img :src="escape" contain class="ma-3 mb-0 rscCardImg"> </v-img>
            <v-card-subtitle
              class="primary--text justify-center text-center pt-0"
              >Escape/Unescape</v-card-subtitle
            >
          </v-card>
        </router-link>
      </v-col>
      <v-col cols="6" md="4" lg="2" class="d-flex justify-center">
        <router-link to="regex-generator">
          <v-card class="rscBox">
            <v-img :src="regex" contain class="ma-3 mb-0 rscCardImg"> </v-img>
            <v-card-subtitle
              class="primary--text justify-center text-center pt-0"
              >RegeX Builder</v-card-subtitle
            >
          </v-card>
        </router-link>
      </v-col>
      <v-col cols="6" md="4" lg="2" class="d-flex justify-center">
        <v-card class="rscBox justify-center align-center d-flex mt-3">
          <span class="primary--text">View All >> </span>
        </v-card>
      </v-col>
    </template>
    <template>
      <v-col
        class="mt-8 pb-1"
        cols="12"
        style="border-bottom: 1px solid #ff6700"
      >
        <strong class="primary--text">Generic Tools</strong>
      </v-col>

      <v-col cols="6" md="4" lg="2" class="d-flex justify-center">
        <v-card class="rscBox justify-center align-center d-flex mt-3">
          <span class="primary--text">Not Ready </span>
        </v-card>
      </v-col>
      <v-col cols="6" md="4" lg="2" class="d-flex justify-center">
        <v-card class="rscBox justify-center align-center d-flex mt-3">
          <span class="primary--text">Not Ready </span>
        </v-card>
      </v-col>
      <v-col cols="6" md="4" lg="2" class="d-flex justify-center">
        <v-card class="rscBox justify-center align-center d-flex mt-3">
          <span class="primary--text">Not Ready </span>
        </v-card>
      </v-col>
      <v-col cols="6" md="4" lg="2" class="d-flex justify-center">
        <v-card class="rscBox justify-center align-center d-flex mt-3">
          <span class="primary--text">Not Ready </span>
        </v-card>
      </v-col>
      <v-col cols="6" md="4" lg="2" class="d-flex justify-center">
        <v-card class="rscBox justify-center align-center d-flex mt-3">
          <span class="primary--text">Not Ready</span>
        </v-card>
      </v-col>
      <v-col cols="6" md="4" lg="2" class="d-flex justify-center">
        <v-card class="rscBox justify-center align-center d-flex mt-3">
          <span class="primary--text">Not Ready </span>
        </v-card>
      </v-col>
      <v-col cols="6" md="4" lg="2" class="d-flex justify-center">
        <v-card class="rscBox justify-center align-center d-flex mt-3">
          <span class="primary--text">View All >> </span>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>
<script>
import resume from "@/assets/resume.svg";
import bioData from "@/assets/bioData.svg";
import certificate from "@/assets/certificate.svg";
import jsonFormat from "@/assets/jsonFormat.svg";
import escape from "@/assets/escape.svg";
import regex from "@/assets/regex.svg";
import { scrollToTop } from "@/helpers/helperFunctions.js";

export default {
  data() {
    return {
      navigator: [
        {
          text: "Quick Access",
          disabled: false,
          href: "quick-access",
        },
        {
          text: "Resources",
          disabled: true,
          href: "",
        },
      ],
      resume,
      bioData,
      certificate,
      jsonFormat,
      regex,
      escape,
    };
  },
  mounted() {
    scrollToTop();
  },
};
</script>
<style>
.rscBox {
  background: #1e1e1e !important;
  /* border: 1px solid #ff6700 !important; */
  color: #ff6700 !important;
  width: 180px;
  height: 180px;
  box-shadow: 0px 0px 20px #000000 !important;
}
.rscCardImg {
  height: 80%;
  width: 80%;
}
.rscBox:hover {
  box-shadow: 0px 0px 11px #ff6700 !important;
}

@media screen and (max-width: 450px) {
  .rscBox {
    width: 160px;
    height: 160px;
  }
}

@media screen and (min-width: 1260px) and (max-width: 1400px) {
  .rscBox {
    width: 160px;
    height: 160px;
    margin-left: 0px;
  }
}
</style>
